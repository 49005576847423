.main {
    width: 100%;
    min-height: 100vh;
    height: auto;
}

@font-face {
    font-family: 'Stylish-Regular';
    src: url('../fonts/Stylish-Regular.ttf') format('truetype');
}

h1 {
    font-family: 'Stylish-Regular';
    font-size: 4rem;
    margin-bottom: 1rem;
}

.plants {
    color: rgb(97, 228, 123);
}

.admin-login {
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.3);
    font-size: 1rem;
}

.admin-login:hover {
    color: white;
}

body::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
body::-webkit-scrollbar-track {
background: '#454545'; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
background-color: #e6e6e6 !important;    /* color of the scroll thumb */
border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px #e6e6e6 /* creates padding around scroll thumb */
}